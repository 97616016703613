import { useMemo } from "react";
import { SortParams, useSortParams } from "~/providers/SortParamsProvider";
import { useCampaign } from "~/providers/CampaignProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { RouterInputs } from "~/utils/api";

export const limit = 50;

const defaultSortParams: SortParams = {
  field: "activity",
  direction: "desc",
};

export type CampaignListParamsType =
  RouterInputs["targetContact"]["getTargetsByCampaignId"];

const useCampaignListParams = () => {
  const campaign = useCampaign();
  const { activeTeamAccount } = useTeamAccount();
  const campaignId = campaign?.id;

  const { userSort, repoSort, userSearch, repoSearch } = useSortParams();

  const userCampaignListParams: CampaignListParamsType = {
    campaignId,
    teamAccountId: activeTeamAccount?.id,
    type: "user",
    searchTerm: userSearch,
    limit,
    sorting: userSort ?? defaultSortParams,
  };
  const repoCampaignListParams: CampaignListParamsType = {
    campaignId,
    teamAccountId: activeTeamAccount?.id,
    type: "repo",
    searchTerm: repoSearch,
    limit,
    sorting: repoSort ?? defaultSortParams,
  };
  return useMemo(
    () => ({
      user: userCampaignListParams,
      repo: repoCampaignListParams,
    }),
    [userCampaignListParams, repoCampaignListParams]
  );
};

export default useCampaignListParams;
