import { useState } from "react";
import ToastProgress from "./ToastProgress";
import { useCampaign } from "~/providers/CampaignProvider";
import ProgressLoadingTargetBatchCreations from "./ProgressLoadingTargetBatchCreation.tsx";
import { ChevronDownIcon } from "@primer/octicons-react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Button from "~/components/base/Button";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import { api } from "~/utils/api";
import useCampaignListParams, {
  type CampaignListParamsType,
} from "../SharedTable/hooks/useCampaignListParams";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
// Update the type of 'utils' to explicitly define it

export const handleRefetchCampaign = async ({
  activeTeamAccountId,
  campaignId,
  user,
  repo,
  utils,
  setIsLoading,
}: {
  activeTeamAccountId: string;
  campaignId: string;
  user: CampaignListParamsType;
  repo: CampaignListParamsType;
  utils: ReturnType<typeof api.useUtils>;
  setIsLoading?: (isLoading: boolean) => void;
}) => {
  setIsLoading && setIsLoading(true);
  await utils.campaign.campaignsList.invalidate({
    teamAccountId: activeTeamAccountId,
  });
  await utils.campaign.campaignById.invalidate({
    teamAccountId: activeTeamAccountId,
    campaignId: campaignId,
  });
  await utils.targetContact.getTargetsByCampaignId.invalidate(user);
  await utils.targetContact.getTargetsByCampaignId.invalidate(repo);
  await utils.targetBatchCreation.getTargetBatchCreationErrorCounts.invalidate({
    teamAccountId: activeTeamAccountId,
    campaignId: campaignId,
  });
  await utils.targetBatchCreation.latestTargetBatchCreationCountsByTypeAndCampaignId.invalidate(
    { teamAccountId: activeTeamAccountId, campaignId: campaignId }
  );
  await utils.evaluation.latestEvaluationCountsByTypeAndCampaignId.invalidate({
    campaignId,
    type: "repo",
    teamAccountId: activeTeamAccountId,
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndCampaignId.invalidate({
    campaignId,
    type: "user",
    teamAccountId: activeTeamAccountId,
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndCampaignId.invalidate({
    campaignId,
    type: "user-dependencies",
    teamAccountId: activeTeamAccountId,
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndCampaignId.invalidate({
    campaignId,
    type: "repo-dependencies",
    teamAccountId: activeTeamAccountId,
  });

  await utils.campaignEvaluation.get.invalidate({
    campaignId: campaignId,
    teamAccountId: activeTeamAccountId,
  });
  setTimeout(() => {
    setIsLoading && setIsLoading(false);
  }, 200);
};

const Toast = ({ isDashboard }: { isDashboard?: boolean }) => {
  const campaign = useCampaign();
  const campaignId = campaign?.id;
  const [showToast, setShowToast] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const utils = api.useUtils();
  const { user, repo } = useCampaignListParams();
  const { activeTeamAccount } = useTeamAccount();
  const { data: latestEvaluationsCountsRepo } =
    api.evaluation.latestEvaluationCountsByTypeAndCampaignId.useQuery(
      {
        campaignId,
        type: "repo",
        teamAccountId: activeTeamAccount?.id,
      },
      { refetchInterval: 1000 * 60 }
    );
  const { data: latestEvaluationsCountsUser } =
    api.evaluation.latestEvaluationCountsByTypeAndCampaignId.useQuery(
      {
        campaignId,
        type: "user",
        teamAccountId: activeTeamAccount?.id,
      },
      { refetchInterval: 1000 * 60 }
    );
  const { data: latestUserDepsEvaluationsCounts } =
    api.evaluation.latestEvaluationCountsByTypeAndCampaignId.useQuery({
      campaignId,
      type: "user-dependencies",
      teamAccountId: activeTeamAccount?.id,
    });

  const { data: latestRepoDepsEvaluationsCounts } =
    api.evaluation.latestEvaluationCountsByTypeAndCampaignId.useQuery({
      campaignId,
      type: "repo-dependencies",
      teamAccountId: activeTeamAccount?.id,
    });

  const { data: targetBatchCreations } =
    api.targetBatchCreation.latestTargetBatchCreationCountsByTypeAndCampaignId.useQuery(
      { teamAccountId: activeTeamAccount.id, campaignId: campaignId },
      { refetchInterval: 60 * 1000 }
    );

  if (
    !latestEvaluationsCountsRepo?.totalEvalCount &&
    !latestEvaluationsCountsUser?.totalEvalCount &&
    !targetBatchCreations?.user.totalTargetContacts &&
    !targetBatchCreations?.repo.totalTargetContacts
  )
    return null;
  return (
    <div
      className={`${
        showToast && "w-[540px]"
      } flex p-3 rounded-t-md bg-gray-100 z-50  fixed right-0 bottom-2`}
    >
      {showToast ? (
        <>
          <div className="flex-1">
            {isDashboard && (
              <Button
                disabled={isLoading}
                className={`mb-2 w-60               
              flex gap-2`}
                onClick={() =>
                  handleRefetchCampaign({
                    activeTeamAccountId: activeTeamAccount.id,
                    campaignId: campaignId,
                    user,
                    repo,
                    utils,
                    setIsLoading,
                  })
                }
              >
                Refresh Table/Dashboards <LoadingSpinner show={isLoading} />
              </Button>
            )}

            <ProgressLoadingTargetBatchCreations
              targetBatchCreations={targetBatchCreations}
            />
            <ToastProgress
              latestDepsEvaluationsCounts={latestRepoDepsEvaluationsCounts}
              latestEvaluationsCounts={latestEvaluationsCountsRepo}
              targetType="repo"
            />

            <ToastProgress
              latestDepsEvaluationsCounts={latestUserDepsEvaluationsCounts}
              latestEvaluationsCounts={latestEvaluationsCountsUser}
              targetType="user"
            />
          </div>
          <button
            className="self-start px-2"
            onClick={() => setShowToast(false)}
          >
            <ChevronDownIcon className="w-6 h-" />
          </button>
        </>
      ) : (
        <button className="rounded-full " onClick={() => setShowToast(true)}>
          <ChevronUpIcon className="w-8 h-8" />
        </button>
      )}
    </div>
  );
};

export default Toast;
