import LoadingBar from "~/loaders/LoadingBar";
import { RouterOutputs } from "~/utils/api";

const ProgressLoadingTargetBatchCreations = ({
  targetBatchCreations,
}: {
  targetBatchCreations:
    | RouterOutputs["targetBatchCreation"]["latestTargetBatchCreationCountsByTypeAndCampaignId"]
    | undefined;
}) => {
  const waitingUserTargetContacts =
    targetBatchCreations?.user?.waitingTargetContacts ?? 0;
  const waitingRepoTargetContacts =
    targetBatchCreations?.repo?.waitingTargetContacts ?? 0;

  const doneUserTargetCreations =
    targetBatchCreations?.user?.doneTargetContacts ?? 0;
  const totalUserTargetCreations =
    targetBatchCreations?.user?.totalTargetContacts ?? 0;

  const doneRepoTargetCreations =
    targetBatchCreations?.repo?.doneTargetContacts ?? 0;
  const totalRepoTargetCreations =
    targetBatchCreations?.repo?.totalTargetContacts ?? 0;
  const userProgress =
    (100 * doneUserTargetCreations) / totalUserTargetCreations;
  const repoProgress =
    (100 * doneRepoTargetCreations) / totalRepoTargetCreations;

  return (
    <div className="z-40 flex flex-col bg-transparent text-xs text-gray-500">
      {waitingUserTargetContacts > 0 && (
        <div className="py-2">
          <div>
            Creating contacts {doneUserTargetCreations} /{" "}
            {totalUserTargetCreations} users
          </div>
          <LoadingBar completed={userProgress} />
        </div>
      )}
      {waitingRepoTargetContacts > 0 && (
        <div className="py-2">
          <div>
            Creating contacts {doneRepoTargetCreations} /{" "}
            {totalRepoTargetCreations} repositories
          </div>
          <LoadingBar completed={repoProgress} />
        </div>
      )}
    </div>
  );
};
export default ProgressLoadingTargetBatchCreations;
